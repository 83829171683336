<template>
  <div class="container-fliud">
    <Header></Header>
    <div class="contactpage">
    <h4 class="contact">Contact</h4>
<hr>
<div class="messageUs">
<div class="row">
<div class="col-md-7" id="leftcol">
    <img src="../../public/images2/Rectangle75.png" alt="">
</div>
<div class="col-md-5">
 
<form @submit.prevent="contactUs">
  <div class="alert alert-success" id="msg" style="display:none"  role="alert" v-if="msg">
                    {{msg}}
   </div>
   <div class="alert alert-danger" id="msgerr" style="display:none"  role="alert" v-if="msgerr">
                    {{msgerr}}
   </div>
<h3>Send Us Message</h3><br>
 <div class="form-group">
    <label for="exampleInputName">Name</label>
    <input type="text" class="form-control" id="exampleInputName" v-model="name" aria-describedby="emailHelp" placeholder="Enter your name">
    <span v-if="msg.name" style="color:red">{{msg.name}}</span>
  </div><br>
  <div class="form-group">
    <label for="exampleInputEmail1">Email address</label>
    <input type="email" class="form-control" id="exampleInputEmail1" v-model="email" aria-describedby="emailHelp" placeholder="Enter your email address">
    <span v-if="msg.email" style="color:red">{{msg.email}}</span>
  </div><br>
 <div class="form-group">
    <label for="exampleInputEmail1">Message</label>
    <textarea name="" id="" class="form-control" cols="30" rows="10" v-model="message"></textarea>
    <span v-if="msg.message" style="color:red">{{msg.message}}</span>
  </div><br>
<div class="form-group">
  <button type="submit" class="btn btn-primary" id="btn">Send message</button>
</div>
</form>
</div>
</div>
</div>
<div class="graybackground">
<div class="row" id="addressrow" >
<div class="col-md-6" id="address">
<h3 style="color:#97c149">Rene kaam</h3>
<h3>INBAR Central Africa Regional Office(CARO)</h3>
<div class="row">
<div class="col-md-3">
<p>Address</p>
</div>
<div class="col-md-9">
Road no. 1860, Behind Bastos Factory, Yaounde 1st District,
</div>
<div class="col-md-3">
<p>Post Address</p>
</div>
<div class="col-md-9">
P O BOX 17056, Yaounde, Cameroon
</div>
<div class="col-md-3">
<p>Telephone</p>
</div>
<div class="col-md-9">
(+237)222217304
</div>
<div class="col-md-3">
<p>Fax</p>
</div>
<div class="col-md-9">
(+237)222217305
</div>
<div class="col-md-3">
<p>Email</p>
</div>
<div class="col-md-9">
caro@inbar.int
</div>
</div>
</div>
<div class="col-md-6">
    <img src="../../public/images2/Rectangle77.png" alt="" style="padding:50px">
</div>
</div>
<div class="row" >
<div class="col-md-6" style="">
    <img src="../../public/images2/Rmana7.png" alt="" style="padding:50px">
</div>
<div class="col-md-6" id="address">
<h3 style="color:#97c149; margin-left:90px">Balidu Kile</h3>
<h3 style="margin-left:90px">INBAR East Africa Regional Office(EARO)</h3>
<div class="row" style="margin-left:50px">
<div class="col-md-3">
<p>Address</p>
</div>
<div class="col-md-9">
9th Floor, Building 2, Yobek Commercial Center,Addis Ababa, Ethopia
</div>

<div class="col-md-3">
<p>Telephone</p>
</div>
<div class="col-md-9">
+251-115-579949
</div>
<div class="col-md-3">
<p>Fax</p>
</div>
<div class="col-md-9">
+251-115-579701
</div>
<div class="col-md-3">
<p>Email</p>
</div>
<div class="col-md-9">
bkifle@inbar.int
</div>
</div>
</div>
</div>
<div class="row">
<div class="col-md-6" id="address">
<h4 style="color:#97c149">Michael Kwaku</h4>
<h4>INBAR West Africa Regional Office(CARO)</h4>
<div class="row">
<div class="col-md-3">
<p>Address</p>
</div>
<div class="col-md-9">
Tropenbos Ghana Building, CSIR-FORIG, Fumesua
</div>
<div class="col-md-3">
<p>Post Address</p>
</div>
<div class="col-md-9">
P O BOX UP 982, KNUST-Kumasi, Ghana
</div>
<div class="col-md-3">
<p>Telephone</p>
</div>
<div class="col-md-9">
+233-3220-61375/60310
</div>

<div class="col-md-3">
<p>Email</p>
</div>
<div class="col-md-9">
mkwaku@inbar.int
</div>
</div>
</div>
<div class="col-md-6">
    <img src="../../public/images2/Rectangle77.png" alt="" style="padding:50px">
</div>
</div>
<div class="row">
<div class="col-md-6">
    <img src="../../public/images2/biulding.png" alt="" style="padding:50px">
</div>
<div class="col-md-6" id="address" >
<h4 style="color:#97c149; margin-left:90px">Andrianjaka Rajaonarison</h4>
<h4 style="margin-left:90px">Madagascar Project Coordinator, INBAR Global Program</h4>
<div class="row" style="margin-left:50px">
<div class="col-md-3">
<p style="color:#97c149">Address</p>
</div> 
<div class="col-md-9">
Tropenbos Ghana Building, CSIR-FORIG, Fumesua
</div>
<div class="col-md-3">
<p style="color:#97c149">Telephone</p>
</div>
<div class="col-md-9">
+261-32070-36049 <br>
+261-3414-28000
</div>

<div class="col-md-3">
<p style="color:#97c149">Email</p>
</div>
<div class="col-md-9">
arajaonarison@inbar.int
</div>
</div>
</div>
</div>
<div class="row">
<div class="col-md-6" id="address">
<h4 style="color:#97c149">Ernest Nti Acheampong</h4>
<h4>INBAR West Africa Regional Office (WARO)</h4>
<div class="row">
<div class="col-md-3">
<p>Address</p>
</div>
<div class="col-md-9">
Tropenbos Ghana Building, CSIR-FORIG, Fumesua
</div>
<div class="col-md-3">
<p>Post Address</p>
</div>
<div class="col-md-9">
P.O.Box UP 928 Kumasi - Ghana
</div>
<div class="col-md-3">
<p>Telephone</p>
</div>
<div class="col-md-9">
+233-3220-61375/60310
</div>

<div class="col-md-3">
<p>Email</p>
</div>
<div class="col-md-9">
mkwaku@inbar.int
</div>
</div>
</div>
<div class="col-md-6">
    <img src="../../public/images2/IMG_0247-612x382.jpg" alt="" style="padding:50px">
</div>
</div>
</div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Navbar';
import Footer from '../components/footer'
import baseUrl from '../baseUrl'
import $ from 'jquery'

export default {
  name: 'Home',
  components: {
    Header,
   Footer
  },
  data() {
    return {
      name:'',
      email:'',
      message:'',
      msg:[],
      msgerr:[]

    }
  },
  methods: {
    contactUs(){
       const emailRegex= /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.email);
    
          if(this.name == ""){
              this.msg['name']="Enter your name";
              return false
          }else if(this.fname.length<3){
              this.msg['name']="Invalid  name";
              return false
          }else{
              this.msg['name']=""
          }
         
          //email
           if(this.email ==""){
              this.msg['email']="Enter email address";
              return false
          }else if(!emailRegex){
              this.msg['email']="Invalid email address";
              return false
          }else{
              this.msg['email']=""
          }
          //last name validation
          if(this.message ==""){
              this.msg['message']="Enter your message";
              return false
          }else{
              this.msg['message']=""
          }
            $('#spinner').show();
      baseUrl.post("/api/contactUsMessage",{
        name:this.name,
        email:this.email,
        message:this.message
      }).then((res)=>{
        $('#msg').show()
        this.msg = res.data.message
        this.name = "",
        this.email="",
        this.message=""
      }).catch((err)=>{
        $('#msgerr').show()
        console.log(err)
        this.msgerr = "Internal server error"
        this.name = "",
        this.email="",
        this.message=""
      })
    }
  },
}
</script>
<style scoped>
.contactpage{
margin-top: 180px;
}
.contact{
padding-left: 70px;
}
.row{
padding: 50px;
}
form{
box-shadow: 0px 2px 8px 1px rgba(156,156,156,1);
padding: 50px;
border-radius: 10px;
}
#btn{
background-color: #97c149;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    width: 200px;
    border: none;
}
#leftcol{
padding: 50px;
}
img{
width: 700px;
}
.graybackground{
padding: 50px;
background-color: #F5F7FA;
}
.col-md-3{
color: rgb(151, 151, 151);
}
.col-md-9{
margin-bottom: 10px;
}
#address{
margin-top: 100px;
}
#addressrow{
padding: 50px;
}
img{
border-top-right-radius: 100px;
border-bottom-left-radius: 100px;
}
</style>